<script setup>
import dataSplitSystem from '@/assets/type_настенный.json';
import dataMultySystem from '@/assets/type_мультисплит-система.json';
import dataKasetSystem from '@/assets/type_кассетный.json';
import dataPotolokSystem from '@/assets/type_потолочный.json';
import dataKolonSystem from '@/assets/type_колонный.json';
import dataKanalSystem from '@/assets/type_канальный.json';
import articlesJson from '@/assets/articles.json';

const split = JSON.parse(JSON.stringify(dataSplitSystem));
const multy = JSON.parse(JSON.stringify(dataMultySystem));
const kaset = JSON.parse(JSON.stringify(dataKasetSystem));
const potolok = JSON.parse(JSON.stringify(dataPotolokSystem));
const kolon = JSON.parse(JSON.stringify(dataKolonSystem));
const kanal = JSON.parse(JSON.stringify(dataKanalSystem));
const articles = JSON.parse(JSON.stringify(articlesJson));

const config = useRuntimeConfig();

const baseURLWithCatalog = `${config.public.baseURL}/catalog`;
const baseURLWithCatalogWithSplitSystem = `${config.public.baseURL}/catalog/split-system`;
const baseURLWithCatalogWithMultiSplitSystem = `${config.public.baseURL}/catalog/multi-split-system`;
const baseURLWithCatalogWithKassetnie = `${config.public.baseURL}/catalog/kassetnie`;
const baseURLWithCatalogWithNapolnie = `${config.public.baseURL}/catalog/napolno-potolochnie`;
const baseURLWithCatalogWithKolonnie = `${config.public.baseURL}/catalog/kolonnie`;
const baseURLWithCatalogWithKanalnie = `${config.public.baseURL}/catalog/kanalnie`;

const scrollY = useScroll();
const templatePage = useTemplatePage();

const AppHeader = resolveComponent(`AppHeader${templatePage.value}`);
const AppFooter = resolveComponent(`AppFooter${templatePage.value}`);
const PopUpConnection = resolveComponent(`PopUpConnection${templatePage.value}`);

const objectLinks = {};

const isData = function (one, second, three, four, five) {
    for (let itemSystem = 0; itemSystem < arguments.length; itemSystem++) {
        for (let itemType = 0; itemType < arguments[itemSystem].length; itemType++) {
            let url = '';
            switch (itemSystem) {
                case 0:
                    url = baseURLWithCatalogWithSplitSystem;
                    break;
                case 1:
                    url = baseURLWithCatalogWithMultiSplitSystem;
                    break;
                case 2:
                    url = baseURLWithCatalogWithKassetnie;
                    break;
                case 3:
                    url = baseURLWithCatalogWithNapolnie;
                    break;
                case 4:
                    url = baseURLWithCatalogWithKolonnie;
                    break;
                case 5:
                    url = baseURLWithCatalogWithKanalnie;
                    break;
            }

            objectLinks[`${arguments[itemSystem][itemType].name}`] = `${url}${arguments[itemSystem][itemType].link}`;
        }
    }
}

const isArticles = function (articles) {
    for (let article = 0; article < articles.length; article++) {
        objectLinks[`${articles[article].title}`] = `${config.public.baseURL}${articles[article].link}`;
    }
}

isData(split[' Настенные сплит-системы (46 товаров)'].model, multy[' Мультисплит-системы (9 товаров)'].model, kaset[' Кассетные сплит-системы (6 товаров)'].model, potolok[' Потолочные сплит-системы (5 товаров)'].model, kolon[' Колонные сплит-системы (2 товара)'].model, kanal[' Канальные сплит-системы (2 товара)'].model);
isArticles(articles.articles);

const buttonUpActive = computed(() => {
    if (scrollY.value > 600) return true;
    else return false;
});

const popUpConnection = ref(false);

const handlerOpenPopUpConnection = () => {
    document.body.style.overflow = 'hidden';
    popUpConnection.value = true;
};

const handlerClosePopUpConnection = () => {
    document.body.style.overflow = 'unset';
    popUpConnection.value = false;
};

const backgroundPageTablet = computed(() => {
    return templatePage.value === 'Mobile' ? 'mobile' : '';
});

const handlerTo = (link) => {
    navigateTo(link);
};
</script>

<template>
    <div class="page" :class="[{ 'page_active-popup': popUpConnection }, backgroundPageTablet]">
        <component :is="AppHeader" @openPopUpConnection="handlerOpenPopUpConnection" />
        <main class="main">
            <slot />
        </main>
        <component :is="AppFooter" />
        <component v-if="popUpConnection" :is="PopUpConnection" @closePopUpConnection="handlerClosePopUpConnection" />
        <div class="page__button-up" :class="{ 'page__button-up_active': buttonUpActive }"
            @click="handlerTo('#header')">
            <div class="page__button-up-circle"></div>
        </div>
        <div class="page__button-phone">
            <div class="page__button-phone-circle">
                <a class="page__button-phone-link" href="tel:+74951430202"></a>
            </div>
        </div>
        <div class="page__button-whatsapp">
            <div class="page__button-whatsapp-circle">
                <a class="page__button-whatsapp-link" href="https://wa.me/79773939575" target="_blank">Кондиционеры
                    купить в Air Arts через WhatsApp</a>
            </div>
        </div>
        <div class="page__hidden-links">
            <a v-for="(link, i) in objectLinks" :href="link" class="page__hidden-link">{{ i }}</a>
        </div>
    </div>
</template>

<style lang="less">
.page {
    min-height: 100vh;
    background: top right / 800px 921px no-repeat url('@/assets/img/main-img.webp'),
        top center / 100% 921px linear-gradient(180deg, #F1F1F1 0%, #F0F2F1 16.25%, #EBEDEC 43.26%, #E6E8E7 72.5%, #DEE2E1 100%);

    &__button {
        &-up {
            cursor: pointer;
            position: fixed;
            bottom: 220px;
            right: 20px;
            width: 80px;
            height: 80px;
            z-index: 50;
            padding: 10px;
            opacity: 0;
            border-radius: 50%;
            pointer-events: none;
            transition: opacity .3s ease;
            background-color: rgba(255, 255, 255, 0.45);
            box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;

            &:hover {
                .page__button-up-circle {
                    background-color: #54e165;
                    transition: background-color .3s ease;
                }
            }

            &-circle {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: center / 25px 25px no-repeat url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDQ0NC44MTkgNDQ0LjgxOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQ0LjgxOSA0NDQuODE5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTQzMy45NjgsMjc4LjY1N0wyNDguMzg3LDkyLjc5Yy03LjQxOS03LjA0NC0xNi4wOC0xMC41NjYtMjUuOTc3LTEwLjU2NmMtMTAuMDg4LDAtMTguNjUyLDMuNTIxLTI1LjY5NywxMC41NjYgICBMMTAuODQ4LDI3OC42NTdDMy42MTUsMjg1Ljg4NywwLDI5NC41NDksMCwzMDQuNjM3YzAsMTAuMjgsMy42MTksMTguODQzLDEwLjg0OCwyNS42OTNsMjEuNDExLDIxLjQxMyAgIGM2Ljg1NCw3LjIzLDE1LjQyLDEwLjg1MiwyNS42OTcsMTAuODUyYzEwLjI3OCwwLDE4Ljg0Mi0zLjYyMSwyNS42OTctMTAuODUyTDIyMi40MSwyMTMuMjcxTDM2MS4xNjgsMzUxLjc0ICAgYzYuODQ4LDcuMjI4LDE1LjQxMywxMC44NTIsMjUuNywxMC44NTJjMTAuMDgyLDAsMTguNzQ3LTMuNjI0LDI1Ljk3NS0xMC44NTJsMjEuNDA5LTIxLjQxMiAgIGM3LjA0My03LjA0MywxMC41NjctMTUuNjA4LDEwLjU2Ny0yNS42OTNDNDQ0LjgxOSwyOTQuNTQ1LDQ0MS4yMDUsMjg1Ljg4NCw0MzMuOTY4LDI3OC42NTd6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==), rgba(92, 205, 106, 1);
                box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;
                transition: background-color .3s ease;
            }

            &_active {
                opacity: 1;
                pointer-events: auto;
                transition: opacity .3s ease;
            }
        }

        &-phone {
            cursor: pointer;
            position: fixed;
            bottom: 120px;
            right: 20px;
            width: 80px;
            height: 80px;
            z-index: 50;
            padding: 10px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.45);
            box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;

            &:hover {
                .page__button-phone-circle {
                    background-color: #54e165;
                    transition: background-color .3s ease;
                }
            }

            &-circle {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: center / 25px 25px no-repeat url('/icon-phone-button.svg'), rgba(92, 205, 106, 1);
                box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;
                transition: background-color .3s ease;
            }

            &-link {
                width: 100%;
                height: 100%;
            }
        }

        &-whatsapp {
            cursor: pointer;
            position: fixed;
            bottom: 20px;
            right: 20px;
            width: 80px;
            height: 80px;
            z-index: 50;
            padding: 10px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.45);
            box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;

            &:hover {
                .page__button-whatsapp-circle {
                    background-color: #54e165;
                    transition: background-color .3s ease;
                }
            }

            &-circle {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: center / 35px 35px no-repeat url('/icons8-whatsapp.svg'), rgba(92, 205, 106, 1);
                box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;
                transition: background-color .3s ease;
            }

            &-link {
                width: 100%;
                height: 100%;
                font-size: 0;
            }
        }
    }

    &__hidden {
        &-links {
            position: absolute;
            left: -1000px;
            top: -1000px;
            visibility: hidden;
        }

        &-link {
            font-family: 'Proxima Nova Rg';
        }
    }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .page {
        background: linear-gradient(270deg, #F2F2F2 0.09%, #F0F2F1 17.41%, #f1f1f1 35.53%, #eaeaea 51.42%, #e6e9eb 66.94%, #e4e6e6 82.21%, #DEE2E1 99.91%);

        &__button {
            &-up {
                bottom: 120px;
                right: 10px;
                width: 50px;
                height: 50px;
                padding: 5px;

                &-circle {
                    width: 40px;
                    height: 40px;
                    background: center / 17px 17px no-repeat url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDQ0NC44MTkgNDQ0LjgxOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQ0LjgxOSA0NDQuODE5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTQzMy45NjgsMjc4LjY1N0wyNDguMzg3LDkyLjc5Yy03LjQxOS03LjA0NC0xNi4wOC0xMC41NjYtMjUuOTc3LTEwLjU2NmMtMTAuMDg4LDAtMTguNjUyLDMuNTIxLTI1LjY5NywxMC41NjYgICBMMTAuODQ4LDI3OC42NTdDMy42MTUsMjg1Ljg4NywwLDI5NC41NDksMCwzMDQuNjM3YzAsMTAuMjgsMy42MTksMTguODQzLDEwLjg0OCwyNS42OTNsMjEuNDExLDIxLjQxMyAgIGM2Ljg1NCw3LjIzLDE1LjQyLDEwLjg1MiwyNS42OTcsMTAuODUyYzEwLjI3OCwwLDE4Ljg0Mi0zLjYyMSwyNS42OTctMTAuODUyTDIyMi40MSwyMTMuMjcxTDM2MS4xNjgsMzUxLjc0ICAgYzYuODQ4LDcuMjI4LDE1LjQxMywxMC44NTIsMjUuNywxMC44NTJjMTAuMDgyLDAsMTguNzQ3LTMuNjI0LDI1Ljk3NS0xMC44NTJsMjEuNDA5LTIxLjQxMiAgIGM3LjA0My03LjA0MywxMC41NjctMTUuNjA4LDEwLjU2Ny0yNS42OTNDNDQ0LjgxOSwyOTQuNTQ1LDQ0MS4yMDUsMjg1Ljg4NCw0MzMuOTY4LDI3OC42NTd6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==), rgba(92, 205, 106, 1);
                }
            }

            &-phone {
                bottom: 65px;
                right: 10px;
                width: 50px;
                height: 50px;
                padding: 5px;

                &-circle {
                    width: 40px;
                    height: 40px;
                    background: center / 17px 17px no-repeat url('/icon-phone-button.svg'), rgba(92, 205, 106, 1);
                }
            }

            &-whatsapp {
                bottom: 10px;
                right: 10px;
                width: 50px;
                height: 50px;
                padding: 5px;

                &-circle {
                    width: 40px;
                    height: 40px;
                    background: center / 25px 25px no-repeat url('/icons8-whatsapp.svg'), rgba(92, 205, 106, 1);
                }
            }
        }
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .page {
        background: top right / 490px 555px no-repeat url('@/assets/img/main-img.webp'),
            top center / 100% 879px linear-gradient(180deg, #f3f1f1 0%, #f1f1f1 16.25%, #e4e6e5 43.26%, #dce0de 72.5%, #DEE2E1 100%);

        &.mobile {
            background: linear-gradient(270deg, #F2F2F2 0.09%, #F0F2F1 17.41%, #f1f1f1 35.53%, #eaeaea 51.42%, #e6e9eb 66.94%, #e4e6e6 82.21%, #DEE2E1 99.91%);
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .page {
        background:
            top right / 630px 739px no-repeat url('@/assets/img/main-img.webp'),
            top center / 100% 958px linear-gradient(180deg, #f3f1f1 0%, #f4f0f0 16.25%, #e8ebea 43.26%, #dfe3e1 72.5%, #e8eaea 100%);
    }
}
</style>
